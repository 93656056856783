.wrapper {
  --mockContentMaxHeight: calc(100vh - 65px - 60px);

  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: clip;

  & [class*='Toolbar_toolbarWrapper'] {
    top: 0;
  }

  & [class*='FilterPanel_filtersCol'] {
    min-height: var(--mockContentMaxHeight);
    height: var(--mockContentMaxHeight);
    top: 65px;
  }

  & [class*='Modal_screenWrapper'] {
    top: 65px;
  }

  &:has(:root) {
    overscroll-behavior: none;
  }
}

.content {
  flex: 1;
  overflow: clip;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  contain: size;
  /* transform: translateZ(0); */
}
