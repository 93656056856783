.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #ffffff;
  border-bottom: 1px solid var(--color-neutral-100);
  position: sticky;
  top: 0;
  z-index: 999;
  height: 65px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 24px;
}

.title {
  flex: 1;
  text-align: center;
  margin: 0 32px;
  position: absolute;
}
