.wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  background-color: #ffffff;
  /* border-top: 1px solid var(--color-neutral-300); */
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  z-index: 999;
  height: 60px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 12px;
  height: 60px;

  display: flex;
  place-content: center;
  flex-direction: column;
  place-items: center;
  font-size: 10px;

  font-weight: 700;
  gap: 4px;

  & svg {
    height: 24px;
    width: 24px;
  }
}
